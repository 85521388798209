import { QuestionsService, AnswersService} from "@/common/api.service"
import { FETCH_QUESTION, FETCH_INDIVIDUAL_QUESTION, ADD_QUESTION, UPDATE_QUESTION, DELETE_QUESTION, QUESTIONS_RESET_STATE, DELETE_ANSWER} from "./actions.type"
import { SET_QUESTION, SET_INDIVIDUAL_QUESTION, SET_ERROR_QUESTION, SET_ERROR_ANSWER, STATE_RESET } from "./mutations.type"

const initialState = () => {
    return {
        questions: [],
        individualquestion: [],
        questionerrors: null,
        answererrors: null,
    }
}

let state = initialState()

const getters = {
    questions(state) {
        return state.questions
    },
    individualquestion(state) {
        return state.individualquestion
    },
}

const actions = {
    async [FETCH_QUESTION](context, slug) {
        const { data } = await QuestionsService.get(slug)
        context.commit(SET_QUESTION, data.data)
        return data;
    },
    async [FETCH_INDIVIDUAL_QUESTION](context, slug) {
        const { data } = await QuestionsService.get(slug)
        context.commit(SET_INDIVIDUAL_QUESTION, data.data)
        return data;
    },  
    async [ADD_QUESTION](context, payload) {
        return await QuestionsService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_QUESTION, error.response.data.errors)
        });
    },     
    async [UPDATE_QUESTION](context, payload) {
        return await QuestionsService.update(payload, payload.question_id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_QUESTION, error.response.data.errors)
        });
    },  
    async [DELETE_QUESTION] (context, id) {
        return await QuestionsService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_QUESTION, error.response.data.errors)
        });
    }, 
    async [DELETE_ANSWER] (context, id) {
        return await AnswersService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_ANSWER, error.response.data.errors)
        });
    },   
    [QUESTIONS_RESET_STATE]({ commit }) {
        commit(STATE_RESET);
    }
}

const mutations = {
    [SET_ERROR_QUESTION](state, error) {
        state.questionerrors = error
    },      
    [SET_QUESTION](state, questionsdata) {
        state.questions = questionsdata
    },
    [SET_INDIVIDUAL_QUESTION](state, individualquestion) {
        state.individualquestion = individualquestion
    },
    [SET_ERROR_ANSWER](state, error) {
        state.answererrors = error
    }, 
    [STATE_RESET](state) {
        Object.assign(state, initialState())
    } 
};

export default {
    state,
    getters,
    actions,
    mutations
};