import { LanguageService } from "@/common/api.service"
import { FETCH_LANGUAGES, LANGUAGES_RESET_STATE } from "./actions.type"
import { SET_LANGUAGES, RESET_LANGUAGES_STATE } from "./mutations.type"


const initialState = () =>{
    return {
        languages: [],
    }
}

export const state = initialState()

const getters = {
    languages(state) {
        return state.languages
    },
}


const actions = {
    async [FETCH_LANGUAGES](context, slug) {
        const { data } = await LanguageService.get(slug);
        context.commit(SET_LANGUAGES, data.data);
        return data;
    },     
    [LANGUAGES_RESET_STATE]({ commit }) {
        commit(RESET_LANGUAGES_STATE);
    }
}

const mutations = {
    [SET_LANGUAGES](state, languages) {
        state.languages = languages
    },    
    [RESET_LANGUAGES_STATE](state) {
        Object.assign(state, initialState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}