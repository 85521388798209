import { ExerciseService } from "@/common/api.service";
import { FETCH_EXERCISES, ADD_EXERCISE, DELETE_EXERCISE, FETCH_INDIVIDUAL_EXERCISE, UPDATE_EXERCISE, RESTORE_EXERCISE, DOWNLOAD_EXERCISE_TEMPLATE, EXERCISE_RESET_STATE } from "./actions.type";
import { SET_EXERCISE, SET_INDIVIDUAL_EXERCISE, SET_EXERCISE_ERROR, RESET_EXERCISE_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        exercises: [],
        individualexercise: {},
        exerciseerrors: null,
    }
} ;

let state = initialState();

const getters = {
    exercises(state) {
        return state.exercises
    },
    individualexercise(state) {
        return state.individualexercise
    },
};

const actions = {
    async [FETCH_EXERCISES](context, slug) {
        const { data } = await ExerciseService.get(slug);
        context.commit(SET_EXERCISE, data.data);
        return data;
    },
    async [FETCH_INDIVIDUAL_EXERCISE](context, moduleid) {
        const { data } = await ExerciseService.get(moduleid);
        context.commit(SET_INDIVIDUAL_EXERCISE, data.data);
        return data;        
    },
    async [ADD_EXERCISE](context, payload) {
        return await ExerciseService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_EXERCISE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    }, 
    async [UPDATE_EXERCISE](context, payload) {
        return await ExerciseService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_EXERCISE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },
    async [DELETE_EXERCISE](context, slug) {
        return await ExerciseService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_EXERCISE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },
    async [RESTORE_EXERCISE] (context, id) {
        return await ExerciseService.restore(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_EXERCISE_ERROR, error.response.data.errors)
        });
    }, 
    async [DOWNLOAD_EXERCISE_TEMPLATE](context, payload) {
    return await ExerciseService.downloadFile(payload)
        .then(data => {
        return data
        })
        .catch(error => {
        context.commit(SET_EXERCISE_ERROR, (error.response.data.hasOwnProperty('errors') ? error.response.data.errors : error.response.data.message));
        });
    },
    [EXERCISE_RESET_STATE]({ commit }) {
        commit(RESET_EXERCISE_STATE);
    }
};

const mutations = {
    [SET_EXERCISE_ERROR](state, error) {
        state.exerciseerrors = error;
    },    
    [SET_EXERCISE](state, exercises) {
        state.exercises = exercises;
    },
    [SET_INDIVIDUAL_EXERCISE](state, module) {
        state.individualexercise = module;
    },
    [RESET_EXERCISE_STATE](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};