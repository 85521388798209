<template>
  <v-app id="app">
    <Navbar v-if="isAuthenticated" :version= "version" />
        <router-view :key="$route.fullPath" />
        <Snackbar :snackbar="snackbarStatus" :message="snackbarMessage"/>        
  </v-app>
</template>

<script>
import Navbar from "./components/navbar/navbar.vue";
import Snackbar from "./components/snackbar/snackbar.vue"
import { mapState } from "vuex"
import store from "@/store"
import axios from "axios"
import { SET_LOADING } from "@/store/mutations.type";
import { version } from "../package.json";

export default {
  name: 'App',
  components: {
    Navbar,
    Snackbar,
  },
  computed : {
    ...mapState({
      snackbarStatus: state => state.globals.snackbarStatus,
      snackbarMessage: state => state.globals.snackbarMessage,
    }),    
    isAuthenticated : function(){ return this.$store.getters.isAuthenticated} ,
  },  
  data: () => ({
    snackbar: false,
    message: null,
    version: version

  }), 
  created(){
  axios.interceptors.request.use(function (config) {
      store.commit(SET_LOADING, true)
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
  } 

};
</script>