import { SurveyService, UserSurveyService } from "@/common/api.service"
import { FETCH_SURVEY, FETCH_INDIVIDUAL_SURVEY, ADD_SURVEY, UPDATE_SURVEY, DELETE_SURVEY, FETCH_DAILY_SURVEY, FETCH_OPEN_SURVEYS, SURVEY_RESET_STATE} from "./actions.type"
import { SET_SURVEY, SET_INDIVIDUAL_SURVEY, SET_ERROR_SURVEY, STATE_RESET } from "./mutations.type"

import { FETCH_USER_SURVEY, DOWNLOAD_USER_SURVEY_CSV } from "./actions.type"
import { SET_USER_SURVEY, SET_ERROR_USER_SURVEY } from "./mutations.type"

const initialState = () => {
    return {
        surveys: [],
        individualsurvey: [],
        surveyerrors: null,

        usersurveys:[],
        usersurveyerrors: null,    
    }
}

let state = initialState()

const getters = {
    surveys(state) {
        return state.surveys
    },
    individualsurvey(state) {
        return state.individualsurvey
    },
    usersurveys(state) {
        return state.usersurveys
    },
}

const actions = {
    async [FETCH_SURVEY](context, slug) {
        const { data } = await SurveyService.get(slug)
        context.commit(SET_SURVEY, data.data)
        return data;
    },
    async [FETCH_INDIVIDUAL_SURVEY](context, slug) {
        const { data } = await SurveyService.get(slug)
        context.commit(SET_INDIVIDUAL_SURVEY, data.data)
        return data;
    },  
    async [ADD_SURVEY](context, payload) {
        return await SurveyService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_SURVEY, error.response.data.errors)
        });
    },     
    async [UPDATE_SURVEY](context, payload) {
        return await SurveyService.update(payload, payload.get('survey_id'))
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_SURVEY, error.response.data.errors)
        });
    },  
    async [DELETE_SURVEY] (context, id) {
        return await SurveyService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_SURVEY, error.response.data.errors)
        });
    }, 
    async [FETCH_DAILY_SURVEY](context, slug) {
        const { data } = await SurveyService.getdailyquiz(slug)
        context.commit(SET_SURVEY, data.data)
        return data;
    }, 
    async [FETCH_OPEN_SURVEYS](context, slug) {
        const { data } = await SurveyService.getopensurveys(slug)
        context.commit(SET_SURVEY, data.data)
        return data;
    }, 
    async [FETCH_USER_SURVEY](context, slug) {
        const { data } = await UserSurveyService.get(slug)
        context.commit(SET_USER_SURVEY, data.data)
        return data;
    },
    async [DOWNLOAD_USER_SURVEY_CSV](context, payload) {
        return await UserSurveyService.downloadFile(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
        context.commit(SET_ERROR_USER_SURVEY, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },     

    [SURVEY_RESET_STATE]({ commit }) {
        commit(STATE_RESET);
    }
}

const mutations = {
    [SET_ERROR_SURVEY](state, error) {
        state.surveyerrors = error
    },      
    [SET_SURVEY](state, surveydata) {
        state.surveys = surveydata
    },
    [SET_INDIVIDUAL_SURVEY](state, individualsurvey) {
        state.individualsurvey = individualsurvey
    },  
    [STATE_RESET](state) {
        Object.assign(state, initialState())
    },
    [SET_USER_SURVEY](state, surveydata) {
        state.usersurveys = surveydata
    },
    [SET_ERROR_USER_SURVEY](state, error) {
        state.usersurveyerrors = error
    }, 
};

export default {
    state,
    getters,
    actions,
    mutations
};