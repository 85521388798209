import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
})

Vue.use(Vuetify)

Vue.use(TiptapVuetifyPlugin, {
  vuetify, 
  iconsGroup: 'md'
})

export default vuetify