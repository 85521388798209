<template>
  <v-main>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12">
          <DataTable
            @editing="editing"
            :title="title"
            :headers="headers"
            :items="companies"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-btn v-if="can_add_company" dark fab fixed bottom right large color="pink" class="mx-2" @click="addCompany">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-main>
</template>


<script>
import { mapGetters } from "vuex";
import store from "@/store";
import DataTable from "@/components/datatable/datatable";
import { FETCH_COMPANIES, COMPANIES_RESET_STATE } from "@/store/actions.type";
import { SET_LOADING } from "@/store/mutations.type";
export default {
  name: "Companies",
  async beforeRouteUpdate(to, from, next) {
    await store.commit(SET_LOADING, true)
    await store.dispatch(COMPANIES_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(COMPANIES_RESET_STATE);
    await store.dispatch(FETCH_COMPANIES, "").then(resp =>{
      if(resp.success){
        store.commit(SET_LOADING, false)
      }
    })

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit(SET_LOADING, true)
    await store.dispatch(COMPANIES_RESET_STATE);
    next();
  },
  computed: {
    ...mapGetters(["companies"]),
    headers(){ return [
        { text: this.$t('Company Prefix'), value: 'company_prefix'},
        { text: this.$t('Company Name'), value: 'company_name' },
        { text: this.$t('Account Type'), value: 'account_type' },
        { text: this.$t('Available Seats'), value: 'available_seats' },
        { text: this.$t('Domain'), value: 'company_domain' },
        { text: this.$t('Status'), value: 'company_status' },
        { text: this.$t('Actions'), value: 'action', sortable: false },
      ]
    },
  },
  components: {
    DataTable
  },
  created(){
    this.can_add_company = process.env.VUE_APP_ENABLE_COMPANY_ADD_BUTTON=='false' ? false : true  // By default can add company
  },
  data: function() {
    return {
      title: "Companies",
      can_add_company : true,
    };
  },
  methods: {
    editing(id) {
      this.$router.push("/company/edit/" + id);
    },
    addCompany() {
      this.$router.push("/company/add/");
    }
  }
};
</script>