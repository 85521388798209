import { SectionHeaderService } from "@/common/api.service";
import { FETCH_SECTION_HEADER_TAGS, ADD_SECTION_HEADER,RESET_SECTION_HEADERS_STATE } from "./actions.type";
import { SET_SECTION_HEADER_TAGS, SET_SECTION_HEADER_ERROR,STATE_SECTION_HEADERS_RESET } from "./mutations.type";

const initialState = () =>{
    return {
        sectionheadertags: [],
        sectionheadererror : [],
    }
} ;

let state = initialState()

const getters = { 
    sectionheadertags(state){
        return state.sectionheadertags
    }   
    
};

const actions = {

    async [FETCH_SECTION_HEADER_TAGS](context, slug) {
        const { data }  = await SectionHeaderService.get(slug);
        context.commit(SET_SECTION_HEADER_TAGS, data.data);
        return data;
    },

    async [ADD_SECTION_HEADER](context, params) {
        return await SectionHeaderService.create(params)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_SECTION_HEADER_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },


    [RESET_SECTION_HEADERS_STATE]({ commit }) {
        commit(STATE_SECTION_HEADERS_RESET);
    }
}

const mutations = {
    [SET_SECTION_HEADER_TAGS](state, sectionheadertags) {
        state.sectionheadertags = sectionheadertags
    },
    [SET_SECTION_HEADER_ERROR](state, sectionheadererror) {
        state.sectionheadererror = sectionheadererror
    },

    [STATE_SECTION_HEADERS_RESET](state) {
        Object.assign(state, initialState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};