import { StripeService } from "@/common/api.service";
import {  FETCH_ALL_INVOICES, FETCH_PRODUCTS_ID, FETCH_PLANS, FETCH_VOUCHER_PRODUCTS_ID, FETCH_TUTOR_PRODUCTS_ID, FETCH_TUTOR_PLANS, FETCH_TUTOR_VOUCHER_PRODUCTS_ID, STRIPE_RESET_STATE } from "./actions.type";
import {  SET_INVOICES, SET_PRODUCTS_ID, SET_PLANS, SET_VOUCHER_PRODUCTS_ID, SET_TUTOR_PRODUCTS_ID, SET_TUTOR_PLANS, SET_TUTOR_VOUCHER_PRODUCTS_ID, RESET_STRIPE_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        invoices: [],
        productsid:[],  
        plans: {},
        vouchersid:[],
        tutorproductsid:[],  
        tutorplans: {},
        tutorvouchersid:[],
    }
};

let state = initialState();

const getters = {
    invoices(state){
        return state.invoices
    },
    productsid(state){
        return state.productsid
    },
    plans(state){
        return state.plans
    },   
    vouchersid(state){
        return state.vouchersid
    },
    tutorproductsid(state){
        return state.tutorproductsid
    },
    tutorplans(state){
        return state.tutorplans
    },   
    tutorvouchersid(state){
        return state.tutorvouchersid
    }
};

const actions = {
    async [FETCH_ALL_INVOICES](context,slug) {
        const { data } = await StripeService.get(slug);
        context.commit(SET_INVOICES, data.data);
        return data; 
    },   
    async [FETCH_PRODUCTS_ID](context,slug) {
        const { data } = await StripeService.get(slug);
        context.commit(SET_PRODUCTS_ID, data.data);
        return data; 
    },   
    async [FETCH_PLANS](context,productdata) {
        const { data } = await StripeService.get('list-plans?stripe_product_id='+productdata.productid+'&region='+productdata.region);
        let payload = { 'data': data.data, 'product': productdata.productid }
        context.commit(SET_PLANS, payload);
        return data; 
    }, 
    async [FETCH_VOUCHER_PRODUCTS_ID](context,slug) {
        const { data } = await StripeService.get(slug);
        context.commit(SET_VOUCHER_PRODUCTS_ID, data.data);
        return data; 
    },     
    async [FETCH_TUTOR_PRODUCTS_ID] (context,slug) {
        const { data } = await StripeService.get(slug);
        context.commit(SET_TUTOR_PRODUCTS_ID, data.data);
        return data; 
    },   
    async [FETCH_TUTOR_PLANS](context,productdata) {
        const { data } = await StripeService.get('list-plans?stripe_product_id='+productdata.productid+'&region='+productdata.region);
        let payload = { 'data': data.data, 'product': productdata.productid }
        context.commit(SET_TUTOR_PLANS, payload);
        return data; 
    }, 
    async [FETCH_TUTOR_VOUCHER_PRODUCTS_ID](context,slug) {
        const { data } = await StripeService.get(slug);
        context.commit(SET_TUTOR_VOUCHER_PRODUCTS_ID, data.data);
        return data; 
    },      
    [STRIPE_RESET_STATE]({ commit }) {
        commit(RESET_STRIPE_STATE);
    }         
};

const mutations = {
    [SET_INVOICES](state, invoices) {
        state.invoices = invoices;
    },
    [SET_PRODUCTS_ID](state, productiddata) {
        state.productsid = productiddata;
    },  
    [SET_PLANS](state, payload) {
        state.plans[payload.product] = payload.data;
    }, 
    [SET_VOUCHER_PRODUCTS_ID](state, voucherdata) {
        state.vouchersid = voucherdata;
    }, 
    [SET_TUTOR_PRODUCTS_ID](state, tutorproductiddata) {
        state.tutorproductsid = tutorproductiddata;
    },  
    [SET_TUTOR_PLANS](state, payload) {
        state.tutorplans[payload.product] = payload.data;
    }, 
    [SET_TUTOR_VOUCHER_PRODUCTS_ID](state, tutorvoucherdata) {
        state.tutorvouchersid = tutorvoucherdata;
    }, 
    [RESET_STRIPE_STATE](state) {
        Object.assign(state, initialState())
    }           
};


export default {
    state,
    getters,
    actions,
    mutations
};
