import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './registerServiceWorker'

import vuetify from './plugins/vuetify';
import ApiService from "./common/api.service";
import ErrorFilter from "./common/error.filter";
import VueInteractJs from "vue-interactjs";

Vue.use(VueInteractJs);
Vue.config.productionTip = false
Vue.filter("error", ErrorFilter);
Vue.filter('readMore', function (text, length, suffix) {
  return text.substring(0, length) + suffix;
})
Vue.filter('stripeHTML', function (text) {
  return text.replace(/<\/?[^>]+(>|$)/g, "")
})
ApiService.init();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')