import { TutorialVideoService } from "@/common/api.service";
import { FETCH_VIDEO_TUTORIAL_DATA, DELETE_VIDEO_TUTORIAL,FETCH_INDIVIDUAL_VIDEO_TUTORIAL_DATA,CREATE_VIDEO_TUTORIAL_DATA, UPDATE_VIDEO_TUTORIAL_DATA,VIDEO_TUTORIAL_STATE_RESET} from "./actions.type";
import { SET_VIDEO_TUTORIAL_DATA, SET_VIDEO_TUTORIAL_ERROR, RESET_VIDEO_TUTORIAL_STATE,SET_INDIVIDUAL_VIDEO_TUTORIAL_DATA } from "./mutations.type";

const initialState = () => {
    return {
        videodata: [],
        individualvideo:{},
        videoerrors: {},
    }
}

let state = initialState()

const getters = {
    videodata(state) {
        return state.videodata
    },
    individualvideo(state) {
        return state.individualvideo
    }
}

const actions = {
    async [FETCH_VIDEO_TUTORIAL_DATA](context, slug) {
        const { data } = await TutorialVideoService.get(slug)
        context.commit(SET_VIDEO_TUTORIAL_DATA, data.data)
        return data;
    },
    async [FETCH_INDIVIDUAL_VIDEO_TUTORIAL_DATA](context, slug) {
        const { data } = await TutorialVideoService.get(slug)
        context.commit(SET_INDIVIDUAL_VIDEO_TUTORIAL_DATA, data.data)
        return data;
    },    
    async [UPDATE_VIDEO_TUTORIAL_DATA](context, payload) {
        return await TutorialVideoService.update(payload)
        .then(({ data }) => {
          context.commit(SET_INDIVIDUAL_VIDEO_TUTORIAL_DATA, data.data)  
          return data
        })
        .catch(error => {
          context.commit(SET_VIDEO_TUTORIAL_ERROR, error.response.data.errors);
        });
    }, 
    async [CREATE_VIDEO_TUTORIAL_DATA](context, params) {
        return await TutorialVideoService.create(params)
            .then(({ data })=> {
                return data
            })
            .catch(error => {
                context.commit(SET_VIDEO_TUTORIAL_ERROR, error.response.data.errors);
            });
    },
    async [DELETE_VIDEO_TUTORIAL](context, slug) {
        return await TutorialVideoService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_VIDEO_TUTORIAL_ERROR, error.response.data)
            });
    },
    [VIDEO_TUTORIAL_STATE_RESET]({ commit }) {
        commit(RESET_VIDEO_TUTORIAL_STATE);
    }
}

const mutations = {
    [SET_VIDEO_TUTORIAL_ERROR](state, error) {
        state.videoerrors = error;
    },
    [SET_VIDEO_TUTORIAL_DATA](state, videodata) {
        state.videodata = videodata
    },
    [SET_INDIVIDUAL_VIDEO_TUTORIAL_DATA](state, individualvideodata) {
        state.individualvideo = individualvideodata
    },
    [RESET_VIDEO_TUTORIAL_STATE](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
