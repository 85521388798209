export const SET_MODULES = 'setModules'
export const SET_GENERAL_DOCUMENTS = 'setGeneralDocuments'
export const SET_COURSE_DOCUMENTS = 'setCourseDocuments'
export const SET_PERSONAL_DOCUMENTS =   'setPersonalDocuments'
export const SET_INDIVIDUAL_GENERAL_DOCUMENT = 'setIndividualGeneralDocument'
export const SET_INDIVIDUAL_PERSONAL_DOCUMENT =   'setIndividualPersonalDocument'
export const SET_INDIVIDUAL_MODULE = 'setIndividualModule'
export const SET_MODULE_ERROR = 'setModuleError'
export const RESET_MODULE_STATE = 'StateModulereset'
export const SET_COURSES = 'setCourses'
export const SET_INDIVIDUAL_COURSE= 'setIndividualCourse' 
export const SET_COURSE_ERROR = 'setCourseError'
export const RESET_COURSE_STATE = 'StateCoursereset'
export const SET_AUTHORS = 'setAuthors'
export const SET_COMPANIES = 'setCompanies'
export const SET_DOCUMENTS  =   'setDocuments'
export const SET_PAGES = 'setPages'
export const SET_LANGUAGES = 'setLanguages'
export const SET_UPLOAD_PROGRESS= 'setUploadProgress'
export const SET_INDIVIDUAL_COMPANY = 'fetchIndividualCompany'
export const SET_INDIVIDUAL_AUTHOR = 'fetchIndividualAuthor'
export const SET_NAVIGATION_BAR_STATUS = 'setNavigationBarStatus'
export const SET_INDIVIDUAL_PAGE = 'setIndividualPage'
export const SET_SECTION_HEADER_TAGS = 'setSectionHeaderTags'
export const SET_AUTH = 'setUser'
export const RESET_AUTH_STATE = 'resetAuthState'
export const SET_INVOICES = 'setInvoices'
export const SET_LOADING = 'setLoading'
export const PURGE_AUTH = 'purgeAuth'
export const SET_ERROR = 'setError'
export const SET_AUTH_ERROR = 'setAuthError'
export const SET_AUTHOR_ERROR = 'setAuthorError'
export const SET_COMPANY_ERROR = 'setCompanyError'
export const SET_PAGE_ERROR =   'setPageError'
export const SET_DOCUMENTS_ERROR = 'setDocumentsError'
export const SET_SNACKBAR   =   'setSnackbar'
export const RESET_STATE = 'Statereset'
export const RESET_COMPANIES_STATE = 'StateCompanyreset'
export const RESET_AUTHORS_STATE = 'StateAuthorsreset'
export const RESET_STRIPE_STATE = 'StateStripereset'
export const RESET_LANGUAGES_STATE = 'StateLanguagereset'
export const RESET_PAGES_STATE = 'StatePagesreset'
export const RESET_DOCUMENTS_STATE   =   'Documentreset'
export const SET_PRODUCTS_ID    =   'setProductsId'
export const SET_PLANS  =   'setPlans'
export const SET_VOUCHER_PRODUCTS_ID    =   'setVoucherProductsId'
export const STATE_SECTION_HEADERS_RESET = 'stateSectionHeadersReset'
export const SET_SECTION_HEADER_ERROR = 'setSectionHeaderError'
export const SET_VIDEO_TUTORIAL_DATA = 'setVideoTutorialData'
export const SET_INDIVIDUAL_VIDEO_TUTORIAL_DATA = 'setIndividualVideoTutorialData'
export const RESET_VIDEO_TUTORIAL_STATE = 'resetVideoTutorialState'
export const SET_VIDEO_TUTORIAL_ERROR = 'setVideoTutorialError'
export const SET_CERTIFICATE_TEMPLATE = 'setCertificateTemplate'
export const SET_INDIVIDUAL_CERTIFICATE_TEMPLATE = 'setIndividualCertificateTemplate'
export const SET_INDIVIDUAL_CERTIFICATE_ERROR = 'setIndividualCertificateTemplateError'
export const RESET_CERTIFICATE_TEMPLATE_STATE = 'CertificateTemplatereset'
export const CLEAR_TEMPLATE_ERROR = 'clearCertificateTemplateErrors'
export const SET_TEAMS = 'setTeams'
export const SET_INDIVIDUAL_TEAM = 'setIndividualTeam'
export const SET_TEAM_ERROR = 'setTeamError'
export const RESET_TEAMS_STATE = 'resetTeamsState'

export const SET_TUTOR_PRODUCTS_ID = 'setTutorProductsId'
export const SET_TUTOR_PLANS = 'setTutorPlans'
export const SET_TUTOR_VOUCHER_PRODUCTS_ID = 'setTutorVoucherProductsId'

export const SET_TUTOR_PRODUCTS = 'setTutorProducts'
export const SET_INDIVIDUAL_TUTOR_PRODUCT = 'setIndividualTutorProduct'
export const SET_TUTOR_PRODUCT_ERROR = 'setTutorProductError'
export const RESET_TUTOR_PRODUCTS_STATE = 'resetTutorProductsState'

export const SET_USERS = 'setUsers'
export const SET_INDIVIDUAL_USER = 'setIndividualUser'
export const SET_USER_ERROR = 'setUserError'
export const RESET_USERS_STATE = 'resetUsersState'

export const SET_CUR_LANGUAGE = 'setCurrentLanguage'
export const SET_USER_STATUS = 'setUserStatus'
export const SET_CUR_USER_LANGUAGE = 'setCurrentUserLanguage'

export const STATE_RESET = 'setStateReset'

export const SET_QUESTION = 'setQuestion'
export const SET_INDIVIDUAL_QUESTION = 'setIndividulQuestion'
export const SET_ERROR_QUESTION = 'setErrorQuestion'
export const SET_ERROR_ANSWER = 'setErrorAnswer'

export const SET_SURVEY = 'setSurvey'
export const SET_INDIVIDUAL_SURVEY = 'setIndividualSurvey'
export const SET_ERROR_SURVEY = 'setErrorSurvey'

export const SET_USER_SURVEY = 'setUserSurvey'
export const SET_ERROR_USER_SURVEY = 'setErrorUserSurvey'


export const SET_TEST_EXAM_QUESTION = 'setTestExamQuestion'
export const SET_INDIVIDUAL_TEST_EXAM_QUESTION = 'setIndividulTestExamQuestion'
export const SET_ERROR_TEST_EXAM_QUESTION = 'setErrorTestExamQuestion'
export const SET_ERROR_TEST_EXAM_ANSWER = 'setErrorTestExamAnswer'
export const SET_TEST_EXAM_QUESTION_TOPIC = 'setTestExamQuestionTopic'

export const SET_TEST_EXAM = 'setTestExam'
export const SET_TEST_DATA = 'setTestData'
export const SET_EXAM_DATA = 'setExamData'
export const SET_INDIVIDUAL_TEST_EXAM = 'setIndividualTestExam'
export const SET_ERROR_TEST_EXAM = 'setErrorTestExam'

export const SET_QUESTION_TOPIC = 'setQuestionTopic'
export const SET_INDIVIDUAL_QUESTION_TOPIC = 'setIndividulQuestionTopic'
export const SET_ERROR_QUESTION_TOPIC = 'setErrorQuestionTopic'

export const SET_EXERCISE = 'setExercise'
export const SET_INDIVIDUAL_EXERCISE = 'setIndividualExercise'
export const SET_EXERCISE_ERROR = 'setExerciseError'
export const RESET_EXERCISE_STATE = 'setExerciseState'
