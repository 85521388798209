import { QuestionTopicService} from "@/common/api.service"
import { FETCH_QUESTION_TOPIC, FETCH_INDIVIDUAL_QUESTION_TOPIC, ADD_QUESTION_TOPIC, UPDATE_QUESTION_TOPIC, DELETE_QUESTION_TOPIC, QUESTION_TOPIC_RESET_STATE} from "./actions.type"
import { SET_QUESTION_TOPIC, SET_INDIVIDUAL_QUESTION_TOPIC, SET_ERROR_QUESTION_TOPIC, STATE_RESET } from "./mutations.type"

const initialState = () => {
    return {
        questiontopics: [],
        individualquestiontopic: [],
        questiontopicerrors: null,
    }
}

let state = initialState()

const getters = {
    questiontopics(state) {
        return state.questiontopics
    },
    individualquestiontopic(state) {
        return state.individualquestiontopic
    },
}

const actions = {
    async [FETCH_QUESTION_TOPIC](context, slug) {
        const { data } = await QuestionTopicService.get(slug)
        context.commit(SET_QUESTION_TOPIC, data.data)
        return data;
    },
    async [FETCH_INDIVIDUAL_QUESTION_TOPIC](context, slug) {
        const { data } = await QuestionTopicService.get(slug)
        context.commit(SET_INDIVIDUAL_QUESTION_TOPIC, data.data)
        return data;
    },  
    async [ADD_QUESTION_TOPIC](context, payload) {
        return await QuestionTopicService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_QUESTION_TOPIC, error.response.data.errors)
        });
    },     
    async [UPDATE_QUESTION_TOPIC](context, payload) {
        return await QuestionTopicService.update(payload, payload.question_topic_id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_QUESTION_TOPIC, error.response.data.errors)
        });
    },  
    async [DELETE_QUESTION_TOPIC] (context, id) {
        return await QuestionTopicService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_QUESTION_TOPIC, error.response.data.errors)
        });
    }, 
    [QUESTION_TOPIC_RESET_STATE]({ commit }) {
        commit(STATE_RESET);
    }
}

const mutations = {
    [SET_ERROR_QUESTION_TOPIC](state, error) {
        state.questiontopicerrors = error
    },      
    [SET_QUESTION_TOPIC](state, questionsdata) {
        state.questiontopics = questionsdata
    },
    [SET_INDIVIDUAL_QUESTION_TOPIC](state, individualquestiontopic) {
        state.individualquestiontopic = individualquestiontopic
    },
    [STATE_RESET](state) {
        Object.assign(state, initialState())
    } 
};

export default {
    state,
    getters,
    actions,
    mutations
};