import { TestExamQuestionsService, TestExamAnswersService, QuestionTopicsService} from "@/common/api.service"
import { FETCH_TEST_EXAM_QUESTION, FETCH_INDIVIDUAL_TEST_EXAM_QUESTION, ADD_TEST_EXAM_QUESTION, UPDATE_TEST_EXAM_QUESTION, DELETE_TEST_EXAM_QUESTION, TEST_EXAM_QUESTIONS_RESET_STATE, DELETE_ANSWER, FETCH_TEST_EXAM_QUESTION_TOPIC, ADD_TEST_EXAM_QUESTION_TOPIC_DATA, RESTORE_TEST_EXAM_QUESTION} from "./actions.type"
import { SET_TEST_EXAM_QUESTION, SET_INDIVIDUAL_TEST_EXAM_QUESTION, SET_ERROR_TEST_EXAM_QUESTION, SET_ERROR_TEST_EXAM_ANSWER, STATE_RESET, SET_TEST_EXAM_QUESTION_TOPIC } from "./mutations.type"

const initialState = () => {
    return {
        testexamquestions: [],
        individualtestexamquestion: [],
        testexamquestionerrors: null,
        testexamquestiontopicdata: [],
        testexamanswererrors: null,
    }
}

let state = initialState()

const getters = {
    testexamquestions(state) {
        return state.testexamquestions
    },
    individualtestexamquestion(state) {
        return state.individualtestexamquestion
    },
    testexamquestiontopicdata(state) {
        return state.testexamquestiontopicdata
    }
}

const actions = {
    async [FETCH_TEST_EXAM_QUESTION](context, slug) {
        const { data } = await TestExamQuestionsService.get(slug)
        context.commit(SET_TEST_EXAM_QUESTION, data.data)
        return data;
    },
    async [FETCH_INDIVIDUAL_TEST_EXAM_QUESTION](context, slug) {
        const { data } = await TestExamQuestionsService.get(slug)
        context.commit(SET_INDIVIDUAL_TEST_EXAM_QUESTION, data.data)
        return data;
    },  
    async [ADD_TEST_EXAM_QUESTION](context, payload) {
        return await TestExamQuestionsService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM_QUESTION, error.response.data.errors)
        });
    },     
    async [UPDATE_TEST_EXAM_QUESTION](context, payload) {
        return await TestExamQuestionsService.update(payload, payload.question_id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM_QUESTION, error.response.data.errors)
        });
    },  
    async [DELETE_TEST_EXAM_QUESTION] (context, id) {
        return await TestExamQuestionsService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM_QUESTION, error.response.data.errors)
        });
    }, 
    async [RESTORE_TEST_EXAM_QUESTION] (context, id) {
        return await TestExamQuestionsService.restore(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM_QUESTION, error.response.data.errors)
        });
    }, 
    async [DELETE_ANSWER] (context, id) {
        return await TestExamAnswersService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM_ANSWER, error.response.data.errors)
        });
    },
    async [FETCH_TEST_EXAM_QUESTION_TOPIC](context, slug) {
        const { data } = await QuestionTopicsService.get(slug)
        context.commit(SET_TEST_EXAM_QUESTION_TOPIC, data.data)
        return data;
    }, 
    async [ADD_TEST_EXAM_QUESTION_TOPIC_DATA](context, payload) {
        return await QuestionTopicsService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM_QUESTION, error.response.data.errors)
        });
    },      
    [TEST_EXAM_QUESTIONS_RESET_STATE]({ commit }) {
        commit(STATE_RESET);
    }
}

const mutations = {
    [SET_ERROR_TEST_EXAM_QUESTION](state, error) {
        state.testexamquestionerrors = error
    },      
    [SET_TEST_EXAM_QUESTION](state, questionsdata) {
        state.testexamquestions = questionsdata
    },
    [SET_INDIVIDUAL_TEST_EXAM_QUESTION](state, individualquestion) {
        state.individualtestexamquestion = individualquestion
    },
    [SET_ERROR_TEST_EXAM_ANSWER](state, error) {
        state.testexamanswererrors = error
    }, 
    [SET_TEST_EXAM_QUESTION_TOPIC](state, questiontopicdata) {
        state.testexamquestiontopicdata = questiontopicdata
    },  
    [STATE_RESET](state) {
        Object.assign(state, initialState())
    } 
};

export default {
    state,
    getters,
    actions,
    mutations
};