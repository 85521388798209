import { TestExamService } from "@/common/api.service"
import { FETCH_TEST_EXAM, FETCH_INDIVIDUAL_TEST_EXAM, ADD_TEST_EXAM, UPDATE_TEST_EXAM, DELETE_TEST_EXAM, FETCH_DAILY_TEST_EXAM, FETCH_OPEN_TEST_EXAMS, TEST_EXAM_RESET_STATE, REMOVE_TEST_FROM_MODULE, RESTORE_TEST_EXAM} from "./actions.type"
import { SET_TEST_EXAM, SET_TEST_DATA, SET_EXAM_DATA, SET_INDIVIDUAL_TEST_EXAM, SET_ERROR_TEST_EXAM, STATE_RESET } from "./mutations.type"

const initialState = () => {
    return {
        testexams: [],
        individualtestexam: [],
        testexamerrors: null,

        testdata: [],
        examdata: [],
    }
}

let state = initialState()

const getters = {
    testexams(state) {
        return state.testexams
    },
    individualtestexam(state) {
        return state.individualtestexam
    },
    testdata(state) {
        return state.testdata
    },
    examdata(state) {
        return state.examdata
    },
}

const actions = {
    async [FETCH_TEST_EXAM](context, slug) {
        const { data } = await TestExamService.get(slug)
        context.commit(SET_TEST_EXAM, data.data)
        if(slug.includes("test")){
            context.commit(SET_TEST_DATA, data.data)
        }
        else if(slug.includes("exam")){
            context.commit(SET_EXAM_DATA, data.data)
        }

        return data;
    },
    async [FETCH_INDIVIDUAL_TEST_EXAM](context, slug) {
        const { data } = await TestExamService.get(slug)
        context.commit(SET_INDIVIDUAL_TEST_EXAM, data.data)
        return data;
    },  
    async [ADD_TEST_EXAM](context, payload) {
        return await TestExamService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM, error.response.data.errors)
        });
    },     
    async [UPDATE_TEST_EXAM](context, payload) {
        return await TestExamService.update(payload, payload.get('test_id'))
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM, error.response.data.errors)
        });
    },  
    async [DELETE_TEST_EXAM] (context, id) {
        return await TestExamService.delete(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM, error.response.data.errors)
        });
    }, 
    async [RESTORE_TEST_EXAM] (context, id) {
        return await TestExamService.restore(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM, error.response.data.errors)
        });
    }, 
    async [FETCH_DAILY_TEST_EXAM](context, slug) {
        const { data } = await TestExamService.getdailyquiz(slug)
        context.commit(SET_TEST_EXAM, data.data)
        return data;
    }, 
    async [FETCH_OPEN_TEST_EXAMS](context, slug) {
        const { data } = await TestExamService.getopentestexams(slug)
        context.commit(SET_TEST_EXAM, data.data)
        return data;
    }, 
    async [REMOVE_TEST_FROM_MODULE](context, payload) {
        return await TestExamService.remove(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_ERROR_TEST_EXAM, error.response.data.errors)
        });
    },  

    [TEST_EXAM_RESET_STATE]({ commit }) {
        commit(STATE_RESET);
    }
}

const mutations = {
    [SET_ERROR_TEST_EXAM](state, error) {
        state.testexamerrors = error
    },      
    [SET_TEST_EXAM](state, testdata) {
        state.testexams = testdata
    },
    [SET_TEST_DATA](state, testdata) {
        state.testdata = testdata
    },
    [SET_EXAM_DATA](state, testdata) {
        state.examdata = testdata
    },
    [SET_INDIVIDUAL_TEST_EXAM](state, individualtestexam) {
        state.individualtestexam = individualtestexam
    },  
    [STATE_RESET](state) {
        Object.assign(state, initialState())
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};