import { AuthorsService } from "@/common/api.service";
import { FETCH_AUTHORS, DELETE_AUTHOR,ADD_AUTHOR, UPDATE_AUTHOR,FETCH_INDIVIDUAL_AUTHOR,AUTHOR_RESET_STATE } from "./actions.type";
import { SET_AUTHORS, SET_INDIVIDUAL_AUTHOR,SET_AUTHOR_ERROR,RESET_AUTHORS_STATE } from "./mutations.type";

const initialState = () =>{
    return { 
        authors: [],
        individualauthor:[],
        authorerrors: null,
    }
};

export const state = initialState();

const getters = {
    authors(state) {
        return state.authors;
    },
    individualauthor(state) {
        return state.individualauthor;
    }    
};

const actions = {
    async [FETCH_AUTHORS](context) {
        const { data } = await AuthorsService.get();
        context.commit(SET_AUTHORS, data.data);
        return data;
    },   
    async [FETCH_INDIVIDUAL_AUTHOR](context,slug) {
        const { data } = await AuthorsService.get(slug);
        context.commit(SET_INDIVIDUAL_AUTHOR, data.data);
        return data;
    },       
    async [ADD_AUTHOR](context, payload) {
        return await AuthorsService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_AUTHOR_ERROR, error.response.data.errors);
        });
    },    
    async [UPDATE_AUTHOR](context, payload) {
        return await AuthorsService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_AUTHOR_ERROR, error.response.data.errors);
        });
    }, 
    async [DELETE_AUTHOR] (context, slug) {
        return await AuthorsService.delete(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_AUTHOR_ERROR, error.response.data.errors);
        });
    },   
    [AUTHOR_RESET_STATE]({ commit }) {
        commit(RESET_AUTHORS_STATE);
    }    
};

const mutations = {
    [SET_AUTHOR_ERROR](state, error) {
        state.authorerrors = error;
    },      
    [SET_AUTHORS](state, authors) {
        state.authors = authors;
    },
    [SET_INDIVIDUAL_AUTHOR](state, authors) {
        state.individualauthor = authors;
    },    
    [RESET_AUTHORS_STATE](state) {
        Object.assign(state, initialState())
    }    
};

export default {
    state,
    getters,
    actions,
    mutations
};