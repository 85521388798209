<template>
    <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :top="true"
    >
      {{ message}}
      <v-btn
        color="success"
        text
        @click="closeSnackbar"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>    
</template>

<script>
    import store from "@/store";
    import { SET_SNACKBAR } from "@/store/mutations.type";
    export default {
    name: 'Snackbar',
    props: {
        message: String,
        snackbar: Boolean,
    },
    watch: {
        snackbar (newvalue) {
            if(newvalue){
                setTimeout(() => {
                    store.commit(SET_SNACKBAR, {status: false, message: ''})
                }, 4000);                
            }
        }
    }, 
    data: () => ({
        timeout: 4000,
    }),    
    methods: {
        closeSnackbar() {
            store.commit(SET_SNACKBAR, {status: false, message: ''})
        },
    },     
    }
</script>