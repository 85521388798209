import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { UserService } from "@/common/api.service";

import {
    LOGIN,
    MICROSOFTLOGIN,
    MICROSOFTCALENDARACCESS,
    MICROSOFTCALLBACK,
    MICROSOFTCALENDARCALLBACK,
    LOGOUT,
    CHECK_AUTH,
    UPDATE_CUR_USER_LANG,
    AUTH_RESET_STATE
} from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_AUTH_ERROR, SET_CUR_USER_LANGUAGE, RESET_AUTH_STATE } from "./mutations.type";

const state = {
    autherrors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
  };

  const getters = {
    currentUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  };

  const actions = {
    [LOGIN](context, credentials) {
      return new Promise(resolve => {
        ApiService.post("login", credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_AUTH_ERROR, response.data.message);
          });
      });
    },
    [MICROSOFTLOGIN](context) {
      return new Promise(resolve => {
        ApiService.get("microsoft-auth/redirect")
          .then(({ data }) => {
            window.location.href=data.data;
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_AUTH_ERROR, (response.data.hasOwnProperty('errors') ? response.data.errors : response.data.message));
          });
      });
    },
    async [MICROSOFTCALLBACK](context, param) {
      return new Promise(resolve => {
        ApiService.get("microsoft-auth/callback"+param)
          .then(({ data }) => {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_AUTH_ERROR, (response.data.hasOwnProperty('errors') ? response.data.errors : response.data.message));
            resolve(response);
          });
      });
    },
    [MICROSOFTCALENDARACCESS](context) {
      return new Promise(resolve => {
        ApiService.get("admins/microsoft-auth/redirect")
          .then(({ data }) => {
            window.location.href=data.data;
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_AUTH_ERROR, (response.data.hasOwnProperty('errors') ? response.data.errors : response.data.message));
          });
      });
    },

    async [MICROSOFTCALENDARCALLBACK](context,slug) {
        const { data } = await UserService.authenticate(slug);
        return data;
    }, 
    [AUTH_RESET_STATE]({ commit }) {
        commit(RESET_AUTH_STATE);
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
    [CHECK_AUTH](context) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("user")
          .then(({ data }) => {
            context.commit(SET_AUTH, data.user);
          })
          .catch(({ response }) => {
            if(response !=undefined)
            context.commit(SET_AUTH_ERROR, response.data.message);
          });
      } else {
        context.commit(PURGE_AUTH);
      }
    },
    [UPDATE_CUR_USER_LANG] (context, payload) {
        context.commit(SET_CUR_USER_LANGUAGE, payload)
        return payload;         
    },
  };

  const mutations = {
    [SET_AUTH_ERROR](state, error) {
      state.autherrors = error;
    },
    [RESET_AUTH_STATE](state) {
      state.autherrors = [];
    },
    [SET_AUTH](state, data) {
      state.isAuthenticated = true;
      state.user = data.user;
      state.autherrors = {};
      JwtService.saveToken(data.token);
      if (JwtService.getToken()) {
        ApiService.setHeader();
      }
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.user = {};
      state.autherrors = {};
      JwtService.destroyToken();
    },
    [SET_CUR_USER_LANGUAGE] (state,user){
        state.user.lang_code = user.lang_code
    },
  };

  export default {
    state,
    actions,
    mutations,
    getters
  };
  