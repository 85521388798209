<template>
    <v-skeleton-loader :loading="loadingdata" type="table">
      <v-data-table :headers="headers" :items="items" :search="search" :footer-props="{ 'items-per-page-text': footertext, 'itemsPerPageOptions':[5,10,20,{ text: $t('All'), value: -1 }]  }" class="elevation-1">
        <template v-slot:item.action="{ item }">
         <v-btn small v-if="item.doc_id!== undefined && item.extension == 'video'" :href="item.file" target="_blank" color="primary">{{ 'View Video' | translate}}</v-btn>
         <v-btn small v-else-if="item.doc_id!== undefined" v-on:click="handleDownload(item)" color="primary">{{ 'View' | translate}}</v-btn>
         <v-icon
            small
            class="mr-2"
            v-if="item.gendoc_id!== undefined && visiblestatus"
            v-on:click="handleDownload(item.doc_files[0])"
          >mdi-briefcase-download</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.company_id!== undefined && !item.user_id"
            v-on:click="handleClick(item.company_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.id!== undefined"
            v-on:click="handleClick(item.id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.gendoc_id!== undefined && !visiblestatus"
            v-on:click="handleClick(item.gendoc_id)"
          >edit</v-icon>  
          <v-icon
            small
            class="mr-2"
            v-if="item.perdoc_id!== undefined"
            v-on:click="handleClick(item.perdoc_id)"
          >edit</v-icon>           
          <v-icon
            small
            class="mr-2"
            v-else-if="item.module_id!== undefined"
            v-on:click="handleClick(item.module_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.author_id!== undefined"
            v-on:click="handleClick(item.author_id)"
          >edit</v-icon>
           <v-icon
            small
            class="mr-2"
            v-else-if="item.tutor_product_id!== undefined"
            v-on:click="handleClick(item.tutor_product_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.team_id!== undefined"
            v-on:click="handleClick(item.team_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.user_id!== undefined"
            v-on:click="handleClick(item.user_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.course_id!== undefined && item.cert_id == undefined && item.cd_id == undefined && item.test_id == undefined"
            v-on:click="handleClick(item.course_id)"
          >edit</v-icon>   
          <v-icon
            small
            class="mr-2"
            v-else-if="item.page_id!== undefined"
            v-on:click="handleClick(item.page_id)"
          >edit</v-icon>
         <v-icon
            small
            class="mr-2"
            v-if="item.cert_id!== undefined"
            v-on:click="handlePreview(item.cert_id)"
          >mdi-eye</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.cert_id!== undefined && item.status == 'active'"
            v-on:click="handleClick(item)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.question_id!== undefined && item.question_identifier== undefined"
            v-on:click="handleClick(item.question_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.survey_id!== undefined && item.course_id== undefined && item.company_user_id == undefined"
            v-on:click="handleClick(item.survey_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.question_topic_id!== undefined"
            v-on:click="handleClick(item.question_topic_id)"
          >edit</v-icon>

          <!-- Copy as new Item -->
          <v-icon
            small
            class="mr-2"
            v-if="item.question_id!== undefined && item.question_identifier!== undefined && item.deleted_at ==null"
            v-on:click="handleCopy(item.question_id)"
          >mdi-content-copy</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.test_id!== undefined && item.deleted_at ==null"
            v-on:click="handleCopy(item.test_id)"
          >mdi-content-copy</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.exercise_id!== undefined && item.deleted_at ==null"
            v-on:click="handleCopy(item.exercise_id)"
          >mdi-content-copy</v-icon>
         

          <!-- Undo Archive -->
          <v-icon
            class="mr-2"
            v-if="item.question_id!== undefined && item.question_identifier!== undefined && item.deleted_at !==null"
            v-on:click="handleRestore(item.question_id)"
          >mdi-history</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.test_id!== undefined && item.deleted_at !==null"
            v-on:click="handleRestore(item.test_id)"
          >mdi-history</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.exercise_id!== undefined && item.deleted_at !==null"
            v-on:click="handleRestore(item.exercise_id)"
          >mdi-history</v-icon>

          <!-- View -->
          <v-icon
            small
            class="mr-2"
            v-if="item.survey_id!== undefined && item.course_id== undefined && item.company_user_id != undefined"
            v-on:click="handleView(item.survey_id)"
          >mdi-eye</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.test_id!== undefined"
            v-on:click="handleView(item.test_id)"
          >mdi-eye</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.exercise_id!== undefined"
            v-on:click="handleView(item.exercise_id)"
          >mdi-eye</v-icon>
        



          <!-- Delete -->
          <v-icon
            small
            class="mr-2"
            v-if="item.cert_id!== undefined"
            v-on:click="handleDelete(item.cert_id)"
          >delete</v-icon>                                  
          <v-icon
            small
            class="mr-2"
            v-if="item.author_id!== undefined"
            v-on:click="handleDelete(item.author_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.tutor_product_id!== undefined"
            v-on:click="handleDelete(item.tutor_product_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.team_id!== undefined && item.tutor_product_id == undefined"
            v-on:click="handleDelete(item.team_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.user_id!== undefined && item.tutor_product_id == undefined"
            v-on:click="handleDelete(item.user_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.module_id!== undefined"
            v-on:click="handleDelete(item.module_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.id!== undefined"
            v-on:click="handleDelete(item.id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.gendoc_id!== undefined"
            v-on:click="handleDelete(item.gendoc_id)"
          >delete</v-icon> 
          <v-icon
            small
            class="mr-2"
            v-if="item.perdoc_id!== undefined"
            v-on:click="handleDelete(item.perdoc_id)"
          >delete</v-icon>           

          <v-icon
            small
            class="mr-2"
            v-if="item.page_id!== undefined"
            v-on:click="handleDelete(item.page_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.cd_id !== undefined"
            v-on:click="handleDelete(item.cd_id)"
          >delete</v-icon>          
          <v-icon
            small
            class="mr-2"
            v-if="item.course_id!== undefined && item.cert_id == undefined && item.module_id == undefined && item.cd_id == undefined && item.test_id == undefined"
            v-on:click="handleDelete(item.course_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.question_id!== undefined && item.deleted_at== undefined"
            v-on:click="handleDelete(item.question_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.survey_id!== undefined && item.course_id == undefined && item.company_user_id == undefined"
            v-on:click="handleDelete(item.survey_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.test_id!== undefined && item.deleted_at== undefined"
            v-on:click="handleDelete(item.test_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.question_topic_id!== undefined"
            v-on:click="handleDelete(item.question_topic_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.exercise_id!== undefined && item.deleted_at== undefined"
            v-on:click="handleDelete(item.exercise_id)"
          >delete</v-icon>

        </template>

        <template v-slot:item.status="{ item }">
          <div v-if="item.status == 'active'"> {{ "Active"|translate }}</div>
          <div v-else> {{ 'Inactive' | translate }}</div>
        </template>
        <template v-slot:item.language="{ item }">
          <div class="my-2" v-if="item.language == 'en' && item.page_id != undefined">
            {{'English'| translate}}
          </div>
          <div class="my-2" v-else-if="item.language == 'de' && item.page_id != undefined">
           {{'German' | translate}}
          </div>          
        </template>
          <template v-slot:top>
          <v-card-title>
            {{ title | translate}}
            <v-spacer></v-spacer>
            <v-btn v-if="title==='General documents' && visiblestatus" v-on:click="addDocuments()"  color="primary">{{ 'Add Documents'| translate}}</v-btn>
            <v-btn v-if="title==='Certifications' && visiblestatus" v-on:click="addCertifications()"  color="primary">{{ 'Add Certifications'| translate}}</v-btn>
          </v-card-title>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" :label="'Search' | translate" single-line hide-details></v-text-field>
          </v-toolbar>      
          </template>        
        <template v-slot:item.pdf="{ item }">
          <div class="my-2">
            <v-btn small color="primary" :href="item.invoice_pdf" target="_blank">{{'Download PDF' | translate}}</v-btn>
          </div>
        </template>
        <template v-slot:item.language.lang_name="{ item }">
         {{item.language.lang_name | translate }}
        </template>
        <template v-slot:item.question_type="{ item }">
         {{item.question_type | translate }}
        </template>
        
        <template v-slot:item.company_status="{ item }">
          <v-chip class="ma-2" :color="item.status == 'active' ? 'success' : item.status == 'inactive' ? 'danger' : 'primary'  ">{{ item.status | translate }}</v-chip>
        </template>
        <template v-slot:item.question_topics="{ item }">
          <v-chip small class="mr-1 pa-2" v-for="(topic, index) in item.question_topics" :key="index">
            {{ topic.question_topic | translate }}
          </v-chip>
        </template>

        <template v-slot:footer.page-text="items"> 
            {{ items.pageStart }} - {{ items.pageStop }} {{ 'of' | translate}} {{ items.itemsLength }} 
          </template>
        

      </v-data-table>
    </v-skeleton-loader>
</template>

<style lang="scss">
.v-data-footer {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.v-application--is-ltr .v-data-footer__select {
  margin-left: 16px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DataTable",
  computed: {
    ...mapGetters(["loadingdata"]),
    footertext(){
      return this.$t('Rows per page')
    },
  },  
  methods: {
    handleClick(id) {
      this.$emit("editing", id);
    },
    handleCopy(id) {
      this.$emit("copying", id);
    },
    handleRestore(id){
      this.$emit("restoring", id);
    },
    handleDownload(data) {
      this.$emit("editing", data);
    },
    handlePreview(data) {
      this.$emit('preview',data)
    },
    handleDelete(id) {
      this.$emit("deleting", id);
    },
    handleView(id){
      this.$emit("showView", id);
    },
    addDocuments(){
      this.$emit("openDocumentbox")
    },    
    addCertifications(){
      this.$emit('openCertificationbox')
    }
  },
  data: function() {
    return {
      search: ''
    }
  },
  props: {
    selected: Array,
    headers: Array,
    items: Array,
    title: String,
    visiblestatus: Boolean,
  }
};
</script>

