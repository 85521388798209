import { CertificateTemplateService } from "@/common/api.service";
import { FETCH_CERTIFICATE_TEMPLATE , PREVIEW_CERTIFICATE_TEMPLATE ,DELETE_CERTIFICATE_TEMPLATE, FETCH_INDIVIDUAL_CERTIFICATE_TEMPLATE ,CREATE_CERTIFICATE_TEMPLATE, UPDATE_CERTIFICATE_TEMPLATE,CERTIFICATE_RESET_STATE } from "./actions.type";
import { SET_CERTIFICATE_TEMPLATE, CLEAR_TEMPLATE_ERROR, SET_INDIVIDUAL_CERTIFICATE_TEMPLATE ,SET_INDIVIDUAL_CERTIFICATE_ERROR, RESET_CERTIFICATE_TEMPLATE_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        certificatetemplates: [],
        individualcertificatetemplates: [],
        certificatetemplateserrors: null,
        certificateerrors: {}
    }
} ;

export const state = initialState();

const getters = {
    certificatetemplates(state) {
        return state.certificatetemplates
    },
    individualcertificatetemplates(state) {
        return state.individualcertificatetemplates
    }
};

const actions = {
    async [FETCH_CERTIFICATE_TEMPLATE](context, Slug) {
        const { data } = await CertificateTemplateService.get(Slug);
        context.commit(SET_CERTIFICATE_TEMPLATE, data.data);
        return data;
    },
    async [FETCH_INDIVIDUAL_CERTIFICATE_TEMPLATE](context, id) {
        const { data } = await CertificateTemplateService.get(id);
        context.commit(SET_INDIVIDUAL_CERTIFICATE_TEMPLATE, data.data);
        return data;        
    },
    async [PREVIEW_CERTIFICATE_TEMPLATE] (context, slug){
        return CertificateTemplateService.get(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_INDIVIDUAL_CERTIFICATE_ERROR, error.response.data.errors);
        });
    },  
    [CREATE_CERTIFICATE_TEMPLATE](context, payload) {
        return CertificateTemplateService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_INDIVIDUAL_CERTIFICATE_ERROR, error.response.data.errors);
        });
    },   
    [UPDATE_CERTIFICATE_TEMPLATE](context, payload) {
        return CertificateTemplateService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_INDIVIDUAL_CERTIFICATE_ERROR, error.response.data.errors);
        });
    },
    [DELETE_CERTIFICATE_TEMPLATE](context, payload) {
        return CertificateTemplateService.delete(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_INDIVIDUAL_CERTIFICATE_ERROR, error.response.data.errors);
        });
    },          
    [CERTIFICATE_RESET_STATE]({ commit }) {
        commit(RESET_CERTIFICATE_TEMPLATE_STATE );
    }
};

const mutations = {
    [SET_INDIVIDUAL_CERTIFICATE_ERROR](state, error) {
        state.certificateerrors = error;
      },  
    [SET_CERTIFICATE_TEMPLATE](state, certificatetemplates) {
        state.certificatetemplates = certificatetemplates
    },
    [SET_INDIVIDUAL_CERTIFICATE_TEMPLATE](state, individualcertificatetemplates) {
        state.individualcertificatetemplates = individualcertificatetemplates
    },
    [CLEAR_TEMPLATE_ERROR](state){
        state.certificateerrors = {}
    },    
    [RESET_CERTIFICATE_TEMPLATE_STATE ](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};