import { PagesService } from "@/common/api.service"
import { FETCH_PAGES, ADD_PAGE,FETCH_INDIVIDUAL_PAGE, DELETE_PAGE,UPDATE_PAGE, PAGES_RESET_STATE } from "./actions.type"
import { SET_PAGES, SET_INDIVIDUAL_PAGE, RESET_PAGES_STATE, SET_PAGE_ERROR } from "./mutations.type"


const initialState = () =>{
    return { 
        pages: [],
        individualpage: [],
        pageerrors: []
    }
} 

let state = initialState()

const getters = {
    pages(state) {
        return state.pages
    },
    individualpage(state) {
        return state.individualpage
    },    
}

const actions = {
    async [FETCH_PAGES](context, slug) {
        const { data } = await PagesService.get(slug)
        context.commit(SET_PAGES, data.data)
        return data
    },
    async [FETCH_INDIVIDUAL_PAGE] (context, id){
        const { data } = await PagesService.get(id)
        context.commit(SET_INDIVIDUAL_PAGE, data.data)
        return data;
    },
    async [ADD_PAGE] (context, params){
        return await PagesService.create(params, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_PAGE_ERROR, error.response.data.errors);
        })
    },
    async [UPDATE_PAGE](context, payload) {
        return await PagesService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_PAGE_ERROR, error.response.data.errors);
        });
    },  
    async [DELETE_PAGE](context, slug) {
        return await PagesService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_PAGE_ERROR, error.response.data.errors);
            });
    },      
    [PAGES_RESET_STATE]({ commit }) {
        commit(RESET_PAGES_STATE)
    }
}

const mutations = {
    [SET_PAGES](state, pages) {
        state.pages = pages
    },
    [SET_PAGE_ERROR](state, error) {
        state.pageerrors = error
    },       
    [SET_INDIVIDUAL_PAGE](state, page){
        state.individualpage = page
    },
    [RESET_PAGES_STATE](state) {
        Object.assign(state, initialState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};