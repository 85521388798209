import { TeamService } from "@/common/api.service";
import { FETCH_TEAMS, DELETE_TEAM,ADD_TEAM, UPDATE_TEAM,FETCH_INDIVIDUAL_TEAM,TEAM_RESET_STATE } from "./actions.type";
import { SET_TEAMS, SET_INDIVIDUAL_TEAM,SET_TEAM_ERROR,RESET_TEAMS_STATE } from "./mutations.type";

const initialState = () =>{
    return {  
        teams: [],
        individualteam:[],
        teamerrors: null,
    }
};

export const state = initialState();

const getters = {
    teams(state) {
        return state.teams;
    },
    individualteam(state) {
        return state.individualteam;
    }    
};

const actions = {
    async [FETCH_TEAMS](context) {
        const { data } = await TeamService.get();
        context.commit(SET_TEAMS, data.data);
        return data;
    },   
    async [FETCH_INDIVIDUAL_TEAM](context,slug) {
        const { data } = await TeamService.get(slug);
        context.commit(SET_INDIVIDUAL_TEAM, data.data);
        return data;
    },       
    async [ADD_TEAM](context, payload) {
        return await TeamService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_TEAM_ERROR, error.response.data.errors);
        });
    },    
    async [UPDATE_TEAM](context, payload) {
        return await TeamService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_TEAM_ERROR, error.response.data.errors);
        });
    }, 
    async [DELETE_TEAM] (context, slug) {
        return await TeamService.delete(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_TEAM_ERROR, error.response.data.errors);
        });
    },   
    [TEAM_RESET_STATE]({ commit }) {
        commit(RESET_TEAMS_STATE);
    }    
};

const mutations = {
    [SET_TEAM_ERROR](state, error) {
        state.teamerrors = error;
    },      
    [SET_TEAMS](state, teams) {
        state.teams = teams;
    },
    [SET_INDIVIDUAL_TEAM](state, teams) {
        state.individualteam = teams;
    },    
    [RESET_TEAMS_STATE](state) {
        Object.assign(state, initialState())
    }    
};

export default {
    state,
    getters,
    actions,
    mutations
};