import { SET_LOADING, SET_SNACKBAR } from "./mutations.type";

const initialState = {
    loadingdata: false,
    snackbarStatus: false,
    snackbarMessage: '',
};

export const state = { ...initialState };

const getters = {
    loadingdata(state) {
        return state.loadingdata;
    },    
};

const mutations = {
    [SET_SNACKBAR](state, payload) {
        state.snackbarStatus = payload.status 
        state.snackbarMessage = payload.message
    },
    [SET_LOADING](state, payload) {
        state.loadingdata = payload 
    },
};

export default {
    state,
    getters,
    mutations
};