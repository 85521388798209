export default {
    "English" : "Englisch",
    "German" : "Deutsch",
    "Username" : "Benutzername",
    "Password" : "Passwort",
    "Login" : "Login",
    "Login with Office" : "Melden Sie sich mit Office an",
    "Username is required" : "Benutzername wird benötigt",
    "Password is required" : "Passwort wird benötigt",
    "Language" : "Sprache",
    "Actions" : "Aktionen",
    "Are you sure want to remove?" : "Möchten Sie dieses Objekt wirklich entfernen?",
    "Close" : "schließen",
    "No" : "Nein",
    "Yes" : "Ja",
    "Remove" : "Entfernen",
    "View Video" : "Video ansehen",
    "View" : "Ansicht",
    "Search" : "Suchen",
    "Course" : "Kurs",
    "Status" : "Status",
    "Active" : "Aktiv",
    "active" : "Aktiv",
    "Draft" : "Entwurf",
    "External User" : "Externer Benutzer",
    "pending" : "Ausstehend",
    "Logout" : "Ausloggen",
    "of" : "von",
    'Rows per page' : 'Einträge per Seite',
    'No data available' : 'Keine Einträge gefunden',

    //Navbar
    "Dashboard" : "Dashboard",
    "Company" : "Unternehmen",
    "Entities" : "Einträge",
    "Authors" : "Autoren",
    "Admins" : "Administratoren",
    "Video tutorial" : "Videoanleitung",
    "Documents" : "Unterlagen",
    "Payments" : "Zahlungen",

    // Tutor Products
    "Tutor Products" : "Tutor-Produkte",
    "Add Tutor Product" : "Tutor-Produkt hinzufügen",
    "Edit Tutor Product" : "Tutor-Produkt bearbeiten",
    "Tutor Product Name" : "Name des Tutor-Produkts",
    "Tutor Product Name is required" : "Produktname ist erforderlich",
    "Tutor Product Description" : "Tutor-Produktbeschreibung",
    "Tutor Product Description is required" : "Produktbeschreibung ist erforderlich",
    "Link to Team" : "Link zum Team",
    "Team is required" : "Mannschaft ist erforderlich",
    "Tutor Product Photo" : "Produktfoto des Tutors",
    "Test Center" : "Testzentrum",

    // Courses
    "Courses" : "Kurse",
    "Domain" : "Domain",
    "Add Course" : "Kurs hinzufügen",
    "Edit Course" : "Kurs bearbeiten",
    "Course name" : "Kursname",
    "Course name is required" : "Kursname ist erforderlich",
    "Tag Line" : "Tag-Linie",
    "Course description is required" : "Kursbeschreibung erforderlich",
    "Abbreviation" : "Abkürzung",
    "Abbreviation is required" : "Abkürzung ist erforderlich",
    "Language is required" : "Sprache ist erforderlich",
    "Author" : "Autor",
    "Certificate description" : "Zertifikatsbeschreibung",
    "Certificate description is required" : "Zertifikatsbeschreibung erforderlich",
    "Glossary" : "Glossar",
    "What Will You Learn?" : "Was wirst du lernen?",
    "Requirements" : "Anforderungen",
    "Long Description" : "Lange Beschreibung",
    "Course image" : "Kursbild",
    "Course status" : "Kursstatus",
    "Course status is required" : "Der Kursstatus ist erforderlich",
    "Course domain" : "Kursdomäne",
    "File name" : "Dateiname",
    "Add certifications" : "Zertifizierungen hinzufügen",
    "Certificate name" : "Zertifikatname",
    "Select certificate" : "Zertifikat auswählen",
    "Font" : "Schriftart",
    "Font size" : "Schriftgröße",
    "Preview" : "Vorschau",
    "Font is required" : "Schriftart ist erforderlich",
    "Font size is required" : "Schriftgröße ist erforderlich",

    // Modules
    "Modules" : "Module",
    "Title" : "Titel",
    "Add Module" : "Modul hinzufügen",
    "Module Title" : "Modultitel",
    "Module Title is required" : "Modultitel ist erforderlich",
    "Thumbnail" : "Miniaturbild",
    "Thumbnail is required" : "Miniaturbild ist erforderlich",
    "Edit Module" : "Modul bearbeiten",
    "Module domain" : "Moduldomain",
    "ADD NEW/ HOTFIX MODULE" : "NEUES/ HOTFIX-MODUL HINZUFÜGEN",
    "Select Module File" : "Wählen Sie Moduldatei",
    "Module File is required" : "Moduldatei ist erforderlich",
    "Module Type" : "Modultyp",
    "Module Type is required" : "Modultyp ist erforderlich",
    "Module File Type" : "Moduldateityp",
    "Module File Type is required" : "Der Moduldateityp ist erforderlich",
    "Module Version" : "Modulversion",
    "Comments" : "Kommentare",
    "Upload" : "Hochladen",
    "Hotfix Version" : "Hotfix-Version",
    "Course domain is required" : "Kursdomain ist erforderlich",
    "Author name is required" : "Der Name des Autors ist erforderlich",
    "Author Description is required" : "Beschreibung des Autors ist erforderlich",
    "Short Description" : "kurze Beschreibung",

    // Companies
    "Companies" : "Firmen",
    "Company Prefix" : "Firmenpräfix",
    "Account Type" : "Konto Typ",
    "Available Seats" : "Verfügbare Plätze",
    "Add Company" : "Firma hinzufügen",
    "Edit Company" : "Firma bearbeiten",
    "Company Pre-fix" : "Firmenpräfix",
    "Company Prefix is required" : "Firmenpräfix ist erforderlich",
    "Company Name" : "Name der Firma",
    "Company Name is required" : "Firmenname ist erforderlich",
    "Admin Salutation" : "Ansprache",
    "Admin Salutation is required" : "Ansprache ist erforderlich",
    "Admin Name" : "Admin Name",
    "Admin Name is required" : "Admin Name ist erforderlich",
    "Admin Email" : "Admin Email",
    "Invalid Admin Email address" : "Ungültige E-Mail Adresse",
    "Admin Phone Number" : "Admin Telefon",
    "Admin Phone Number is required" : "Admin Telefonnummer ist erforderlich",
    "Available Seats is required" : "verfügbare Plätze ist erforderlich",
    "Company Logo" : "Firmenlogo",
    "Company Color" : "Firmenfarbe",
    "Company domain" : "Unternehmensdomain",
    "Company Language" : "Firmensprache",
    "Company Language is required" : "Firmensprache ist erforderlich",
    "Payment Account" : "Konto für Zahlungen",
    "Payment Account is required" : "Konto für Zahlungen ist erforderlich",
    "Company Address" : "Firmenanschrift",
    "Company Address is required" : "Firmenadresse ist erforderlich",
    "Personal Documents" : "Persönliche Dokumente",
    "Personal documents is required" : "Persönliche Dokumente sind erforderlich",
    "Equipment status" : "Equipmentstatus",
    "Equipment status is required" : "Equipmentstatus wird benötigt",
    "Task center status" : "Taskstatus",
    "Task center status is required" : "Taskstatus wird benötigt",
    "Show Inbox" : "Inbox anzeigen",
    "Show Inbox is required" : "Inbox anzeigen wird benötigt",
    "Show External Users" : "externe Benutzer anzeigen",
    "External Users is required" : "externe Benutzer anzeigen wird benötigt",
    "Feedback Emails" : "Feedback Emails",
    "Course feedback emails are required" : "Kurs Feedback Email werden benötigt",
    "Daily stats" : "Tägliche Statistik",
    "Daily stats is required" : "Tägliche Statistik wird benötigt",
    "Show Statistics" : "Statistiken anzeigen",
    "Statistics Option is required" : "Statistiken anzeigen Option wird benötigt",
    "Inquiry Email" : "Anfragen Email",
    "Inquiry Email is required" : "Anfragen Email wird benötigt",
    "Login Options" : "Anmeldeoptionen",
    "Login Option is required" : "Anmeldeoption ist erforderlich",
    "Show Quiz" : "Quiz anzeigen",
    "Show Quiz is required" : "Quiz anzeigen Option wird benötigt",  
    "Selected Courses" : "Ausgewählte Kurse",
    "Course Products" : "Kursprodukte",
    "Course Product Pricing Plans" : "Preispläne für Kursprodukte",
    "Has Voucher Products?" : "Hat Gutscheinprodukte?",
    "Voucher Products" : "Gutschein-Produkte",
    "Voucher Product Pricing Plan" : "Preisplan für Gutscheinprodukte",
    "Applied Voucher Product Pricing Plan" : "Angewendeter Gutschein-Produktpreisplan",
    "Has Sort?" : "Hat Sort?",
    "Search Modules" : "Suchmodule",
    "Has Tutor Product" : "Hat Tutor-Produkt",
    "Tutor Product" : "Tutor-Produkt",
    "Selected Tutor Products" : "Ausgewählte Tutor-Produkte",
    "Tutor Product Pricing Plan" : "Preisplan für Tutor-Produkte",
    "STAGING" : "INSZENIERUNG",
    "PRODUCTION" : "PRODUKTION",
    "Mr" : "Herr",
    "Ms" : "Ms",
    "Mrs" : "Frau",
    "Payment Account and Account type will be locked, Are you sure you want to proceed?" : "Zahlungskonto und Kontotyp werden gesperrt. Möchten Sie wirklich fortfahren?",
    "Calendar User ID" : "Kalender-Benutzer-ID",
    "Object ID of User in Microsoft 365 That is responsible for adding new calendars" : "Objekt-ID des Benutzers in Microsoft 365, die für das Hinzufügen neuer Kalender verantwortlich ist",
    "Object ID of Microsoft 365 User" : "Objekt-ID des Microsoft 365-Benutzers",
    "Calendar Users" : "Kalenderbenutzer",

    // Authors
    "Author Name" : "Autorenname",
    "Add Author" : "Autor hinzufügen",
    "Edit Author" : "Autor bearbeiten",
    "Author Name is required" : "Der Name des Autors ist erforderlich",
    "Author Information" : "Informationen zum Autor",
    "Author Information is required" : "Autoreninformationen sind erforderlich",
    "Author Photo" : "Autor Foto",

    // Teams
    "Teams" : "Mannschaften",
    "Team Name" : "Teamname",
    "Contact" : "Kontakt",
    "Website" : "Webseite",
    "Add Tutor Team" : "Lehrerteam hinzufügen",
    "Edit Tutor Team" : "Lehrerteam bearbeiten",
    "Team Name is required" : "Teamname ist erforderlich",
    "Team Description" : "Teambeschreibung",
    "Team Description is required" : "Teambeschreibung ist erforderlich",
    "Team Contact Info" : "Team-Kontaktdaten",
    "Contact Info is required" : "Kontaktinformationen sind erforderlich",
    "Website is required" : "Webseite ist erforderlich",
    "User Booking Details" : "Benutzerbuchungsdetails",
    "User Booking Details is required to send email" : "Benutzerbuchungsdetails sind erforderlich, um eine E-Mail zu senden",
    "Booking Emails" : "Buchungs-E-Mails",
    "Team Photo" : "Mannschaftsfoto",

    // Admins
    "Salutation" : "Anrede",
    "Name" : "Name",
    "User Name" : "Benutzername",
    "Email" : "Email",
    "Job Position" : "Job Titel",
    "Phone Number" : "Telefonnummer",
    "Phone No" : "Telefonnummer",
    "Add Admin" : "Administrator hinzufügen",
    "Edit Admin" : "Administrator bearbeiten",
    "Salutation is required (eg Mr)" : "Anrede erforderlich (z. B. Herr)",
    "Name is required" : "Name ist erforderlich",
    "Email is required" : "E-Mail ist erforderlich",
    "Job Position is required" : "Job Titel ist erforderlich",
    "Phone Number is required" : "Telefonnummer ist erforderlich",
    "Authorize Calendar User" : "Kalenderbenutzer autorisieren",
    "You do not have enough permissions. Please contact administrator" : "Sie verfügen nicht über ausreichende Berechtigungen. Bitte wenden Sie sich an den Administrator",
    "Calendar Admins" : "Kalenderadministratoren",
    "*You need to have appropriate permissions to link account" : "*Sie benötigen die entsprechenden Berechtigungen, um das Konto zu verknüpfen",

    // Pages
    "Pages" : "Seiten",
    "Order" : "Befehl",
    "Page slug" : "Page-Slug",
    "Add Page" : "Seite hinzufügen",
    "Edit Page" : "Seite bearbeiten",
    "Page title" : "Seitentitel",
    "Page title is required" : "Seitentitel ist erforderlich",
    "Page slug is required" : "Seiten-Slug ist erforderlich",
    "Icon code" : "Symbolcode",
    "Icon is required" : "Symbol ist erforderlich",
    "Order is required" : "Bestellung ist erforderlich",
    "Shortcodes" : "Kurzwahlen",
    "Company address" : "Firmenanschrift",
    "Company Url" : "Firmen-URL",
    "Admin Phone" : "Admin-Telefon",
    "Admin name" : "Admin-Name",
    "Content" : "Inhalt",
    "Click to view icons" : "Klicken Sie, um Symbole anzuzeigen",

    
    // Payments
    "Invoice Number" : "Rechnungsnummer",
    "Date" : "Datum",
    "Amount" : "Menge",

    // Video tutorials
    "Video tutorials" : "Videoanleitungen",
    "Video name" : "Videoname",
    "Add Video tutorial" : "Video-Tutorial hinzufügen",
    "Edit Video tutorial" : "Video-Tutorial bearbeiten",
    "Route" : "Route",
    "Link" : "Verknüpfung",
    "Route is required" : "Route ist erforderlich",
    "Video link is required" : "Videolink ist erforderlich",


    //General documents
    "General documents" : "Allgemeine Dokumente",
    "Certifications" : "Zertifizierungen",
    "Type" : "Typ",
    "Add General document" : "Allgemeines Dokument hinzufügen",
    "Edit General document" : "Allgemeines Dokument bearbeiten",
    "General document name" : "Allgemeiner Dokumentname",
    "General document name is required" : "Allgemeiner Dokumentname ist erforderlich",
    "Type of documents" : "Art der Dokumente",
    "Document type is required" : "Dokumententyp ist erforderlich",
    "Direct link" : "Direkte Verbindung",
    "Section header" : "Abschnittsüberschrift",
    "Add Tag" : "Tag hinzufügen",
    "Add" : "Hinzufügen",
    "Type is required" : "Typ ist erforderlich",
    "Description" : "Beschreibung",
    "Description is required" : "Beschreibung ist erforderlich",
    "Click to copy link" : "Klicken Sie hier, um den Link zu kopieren",
    "Click to copy direct link" : "Klicken Sie hier, um den direkten Link zu kopieren",
    "Upload date" : "Hochgeladen am",
    "Instruction" : "Anweisung",
    "Standard" : "Standard",
    "Procedure" : "Verfahren",
    "Manual" : "Handbuch",
    "Audit" : "Prüfung",
    "QM-System" : "QM-System",
    "Learning resource" : "Lernressource",
    "Add Documents" : "Dokumente hinzufügen",
    "Add Certifications" : "Zertifizierungen hinzufügen",
    "Download PDF" : "PDF Herunterladen",
    "Hidden until course is passed" : "Verborgen, bis der Kurs bestanden ist",

    'Question': 'Frage',
    'Questions': 'Fragen',
    'Add Question' : 'Frage hinzufügen',
    'Edit Question' : 'Frage bearbeiten',
    'Question id' : 'Fragen-ID',
    'Question name is required' : 'Name der Frage ist erforderlich',
    'Question text is required' : 'Fragetext ist erforderlich',
    'Question help text is required': 'Hilfetext für die Frage ist erforderlich',
    'At least one Question Topic is required': 'Mindestens ein Fragethema ist erforderlich',
    'Question Type is required': 'Fragetyp ist erforderlich',
    'The Topic ' : 'Das Thema',
    ' is already added. Please select from the list or add a new topic.' : 'ist bereits hinzugefügt. Bitte wählen Sie aus der Liste oder fügen Sie ein neues Thema hinzu.',
    'Answers' : 'Antworten',
    'Answer' : 'Antworten',
    'Question name' : 'Name der Frage',
    'Question text' : 'Fragetext',
    'Question Help Text' : 'Frage Hilfetext',
    'Question Type' : 'Fragetyp',
    'Question Topic' : 'Frage Thema',
    'Add Topic' : 'Thema hinzufügen',
    'Points' : 'Punkte',
    'Answer is required' : 'Antwort ist erforderlich',
    "Add New Question" : "Neue Frage hinzufügen",
    "Add Question (Copied)" : "Frage hinzufügen (kopiert)",
    "Please Make necessary changes" : "Bitte nehmen Sie die erforderlichen Änderungen vor",
    "Question identifier" : "Fragenbezeichner",
    "Add Topic Description" : "Themenbeschreibung hinzufügen",
    "Displaying all deleted questions here." : "Hier werden alle gelöschten Fragen angezeigt.",

    "Add Question Topic" : "Fragethema hinzufügen",
    "Edit Question Topic" : "Fragethema bearbeiten",
    "Question Topic name" : "Name des Fragethemas",
    "Displaying all question topics here." : "Hier werden alle Fragethemen angezeigt.",

    "Survey" : "Umfrage",
    "Surveys" : "Umfragen",
    "Survey Results" : "Umfrageergebnisse",
    "Start Date" : "Startdatum",
    "End Date" : "Endtermin",
    "Download CSV" : "CSV herunterladen",
    "Answered Questions" : "Beantwortete Fragen",
    "Course Name" : "Kursname",

    "Survey Name" : "Umfragename",
    "Created At" : "Hergestellt in",
    "Add Survey" : "Umfrage hinzufügen",
    "Edit Survey" : "Umfrage bearbeiten",
    "Survey name" : "Name der Umfrage",
    "Survey Description" : "Beschreibung der Umfrage",
    "Select Questions" : "Wählen Sie Fragen aus",
    "Survey image" : "Umfragebild",
    "Survey Language" : "Umfragesprache",
    "Questions Selected" : "Ausgewählte Fragen",
    "Survey contains a total of " : "Die Umfrage enthält insgesamt ",
    "questions" : "fragen",
    'Order Index' : 'Reihenfolge',
    "Expires On" : "Läuft aus am",
    "SurveyAnswers" : "UmfrageAntworten",

    "Exam Test Questions" : "Prüfungsfragen",
    "Question Topics" : "Fragethemen",
    "Displaying all questions from exam/tests here." : "Hier werden alle Fragen aus Prüfungen/Tests angezeigt.",
    "Show all Questions" : "Zeige alle Fragen",
    "Show all Archived" : "Alle archivierten Fragen anzeigen",
    "Question Topic id" : "ID Fragenthema",
    "single-select" : "Einfachauswahl",
    "multiple-select" : "Mehrfachauswahl",
    "text" : "Text",
    "rating" : "Bewertung",
    "Please fill Question Topic and Question Topic Description fields" : "Bitte füllen Sie die Felder Fragethema“ und Fragethemabeschreibung aus",

    "Displaying all tests here." : "Hier werden alle Tests angezeigt.",
    "Displaying all deleted tests here." : "Hier werden alle gelöschten Tests angezeigt.",
    "Show all Tests" : "Alle Tests anzeigen",
    "Test id" : "Test-ID",
    "Add New Test" : "Neuen Test hinzufügen",
    "Add Test (Copied)" : "Test kopieren",
    "Please Make necessary changes to this Test" : "Bitte nehmen Sie die erforderlichen Änderungen an diesem Test vor",
    "Test name" : "Testname",
    "Test name is required" : "Testname ist erforderlich",
    "Passing Percent(%)" : "Bestehensprozentsatz (%)",
    "Passing Percent is required" : "Das Bestehen von Prozent ist erforderlich",
    "Has Randomized Subset" : "Hat eine randomisierte Teilmenge",
    "Randomized Question Count" : "Anzahl der zufälligen Fragen",
    "Timeout (In minutes)" : "Wartezeit (in Minuten)",
    "Time Limit (In minutes)" : "Zeitlimit (in Minuten)",
    "Message 1" : "Nachricht 1",
    "Message 2" : "Nachricht 2",
    "Message 3" : "Nachricht 3",
    "Enter a message when the user passes for the first time" : "Geben Sie eine Nachricht ein, die den Teilnehmern gezeigt wird welche beim ersten Versuch bestehen.",
    "Enter a message when the user has failed previously and fails again. The user's most recent test score is compared to their last test score and displayed." : "Geben Sie eine Nachricht ein, die den Teilnehmern gezeigt wird welche beim letzten Test nicht bestanden haben und einen erneuten fehlgeschlagenen Versuch verzeichnen. Das letzte Testergebnis des Benutzers wird mit seinem jetzigen Testergebnis verglichen und angezeigt.",
    "Enter a message when the user has failed previously and now passes. The user's most recent test score is compared to their last test score and displayed." : "Geben Sie eine Nachricht ein, die den Teilnehmern gezeigt wird welche beim letzten Test nicht bestanden haben und nun den Test bestanden haben. Das letzte Testergebnis des Benutzers wird mit seinem jetzigen Testergebnis verglichen und angezeigt.",
    "Please enter a message when the user passes for the first time" : "Bitte geben Sie eine Nachricht ein, wenn der Benutzer zum ersten Mal vorbeikommt",
    "Please enter a message when the user has failed previously and fails again. The user's most recent test score is compared to their last test score and displayed." : "Bitte geben Sie eine Nachricht ein, wenn der Benutzer zuvor fehlgeschlagen ist und erneut fehlschlägt. Das letzte Testergebnis des Benutzers wird mit seinem letzten Testergebnis verglichen und angezeigt.",
    "Please enter a message when the user has failed previously and now passes. The user's most recent test score is compared to their last test score and displayed." : "Bitte geben Sie eine Nachricht ein, wenn der Benutzer zuvor fehlgeschlagen ist und nun erfolgreich ist. Das letzte Testergebnis des Benutzers wird mit seinem letzten Testergebnis verglichen und angezeigt.",
    "View Test" : "Test ansehen",
    "Message when the user passes the first time" : "Meldung, die den Teilnehmern gezeigt wird welche beim ersten Versuch bestehen.",
    "Message when the user has failed previously and fails again. The user’s most recent test score is compared to their last test score and displayed" : "Meldung, die den Teilnehmern gezeigt wird welche beim letzten Test nicht bestanden haben und einen erneuten fehlgeschlagenen Versuch verzeichnen. Das letzte Testergebnis des Benutzers wird mit seinem jetzigen Testergebnis verglichen und angezeigt.",
    "Message when the user has failed previously and now passes. The user’s most recent test score is compared to their last test score and displayed" : "Meldung, die den Teilnehmern gezeigt wird welche beim letzten Test nicht bestanden haben und nun den Test bestanden haben. Das letzte Testergebnis des Benutzers wird mit seinem jetzigen Testergebnis verglichen und angezeigt.",
    'contains a total of ' : 'enthält insgesamt ',
    ' Question. Out of which ' : ' Frage. Aus welchen ',
    ' Questions. Out of which ' : ' Fragen. Aus welchen ',
    ' Question will be shown, ' : ' Die Frage wird angezeigt, ',
    ' Questions will be shown, ' : ' Fragen werden angezeigt, ',
    ' is a Fixed Question and ' : ' ist eine feste Frage und ',
    ' are Fixed Questions and ' : ' sind feste Fragen und ',
    ' no ' : ' nein ',
    ' Question is randomized. ' : ' Die Frage ist randomisiert. ',
    ' Questions are randomized. ' : ' Die Fragen werden randomisiert. ',
    'YES' : 'JA',
    'NO' : 'NEIN',
    "Fixed Question" : "Feste Frage",
    "Question Name" : "Fragename",
    "Open All Answers" : "Alle Antworten öffnen",
    'Close All' : 'Alle schließen',
    "Points are shown in a blue bage icon (once a question is opened)" : "Punkte werden in einem blauen Symbol angezeigt (sobald eine Frage geöffnet ist)",

    "Tests" : "Prüfen",
    "Exams" : "Prüfungen",
    "Show all Exams" : "Alle Prüfungen anzeigen",
    "Show all Archived Exams" : "Alle archivierten Prüfungen anzeigen",
    "Displaying all deleted exams here" : "Hier werden alle gelöschten Prüfungen angezeigt",
    "Displaying all exams here." : "Hier werden alle Prüfungen angezeigt.",
    "Exam id" : "Prüfungs-ID",
    "Add New Exam" : "Neue Prüfung hinzufügen",
    "Add Exam (Copied)" : "Prüfung kopieren",
    "Please Make necessary changes to this Exam" : "Bitte nehmen Sie die erforderlichen Änderungen an dieser Prüfung vor",
    "Exam name" : "Prüfungsname",
    "Enter a message when the user passes the Exam" : "Geben Sie eine Nachricht ein, die den Teilnehmern gezeigt wird welche die Prüfung bestehen.",
    "Enter a message when the user fails the Exam" : "Geben Sie eine Nachricht ein, die den Teilnehmern gezeigt wird welche die Prüfung nicht bestehen.",
    "View Exam" : "Prüfung ansehen",
    "Exam Name" : "Prüfungsname",
    "Pass Message" : "Bestandenmeldung",
    "Message when the user has passed the Exam" : "Meldung, die den Teilnehmern gezeigt wird welche die Prüfung bestehen.",
    "Fail Message" : "Meldung fehlerhafter Versuch",

    //Exercise
    "Exercises" : "Übungen",
    "Displaying all exercises here." : 'Hier werden alle Übungen angezeigt.',
    "Displaying all deleted exercises here." : "Hier werden alle gelöschten Übungen angezeigt.",
    "Add Exercise" : "Übung hinzufügen",
    "Edit Exercise" : "Übung bearbeiten",
    "Exercise Name" : "Übungsname",
    "Exercise Name is required" : "Übungsname ist erforderlich",
    "Exercise description is required" : "Eine Übungsbeschreibung ist erforderlich",
    "Exercise Image" : "Übungsbild",
    "Exercise Image is required" : "Übungsbild ist erforderlich",
    "First Page Instructions" : "Anweisungen auf der ersten Seite",
    "Exercise Instructions" : "Übungsanweisungen",
    "Add resource" : "Ressource hinzufügen",
    "Resource is required" : "Ressource ist erforderlich",
    "Upload resource" : "Ressource hochladen",
    "Result Page Instructions" : "Anweisungen zur Ergebnisseite",
    "View Exercise" : "Übung ansehen",
    "Exercise Resource" : "Übungsressource",
    "Download Exercise" : "Übung herunterladen",

    //Calendar
    "Calendar event name that is required for a user to attend. Make sure you have this added in the name of the calendar event in microsoft calendar eg ##ARBEITEVENT##" : "Name des Kalenderereignisses, das für die Teilnahme eines Benutzers erforderlich ist. Stellen Sie sicher, dass Sie dies im Namen des Kalenderereignisses im Microsoft-Kalender hinzugefügt haben, z. B. ##ARBEITEVENT##",
    "Event Key description for the user to prompt the user to join the event inorder to pass the course." : "Beschreibung des Ereignisschlüssels für den Benutzer, um ihn aufzufordern, an der Veranstaltung teilzunehmen, um den Kurs zu bestehen.",
    "Calendar Event Key" : "Kalenderereignisschlüssel",
    "Event Key Description" : "Beschreibung des Ereignisschlüssels",
    "Downtime Calendar Name" : "Name des Ausfallzeitkalenders",
    "Name of the Downtime Calendar" : "Name des Ausfallzeitkalenders",
    "Course Expiry Calendar Name" : "Name des Kursablaufkalenders",
    "Name of the Course Expiry Calendar" : "Name des Kursablaufkalenders",
    "Course Expiry Event Description" : "Beschreibung des Kursablaufereignisses",
    "Downtime Calendar name is required" : "Der Name des Ausfallzeitkalenders ist erforderlich",
    "Course Expiry Calendar name is required" : "Der Name des Kursablaufkalenders ist erforderlich",
    "Calendar User Object ID is required" : "Die Kalenderbenutzerobjekt-ID ist erforderlich",


 };