import { SET_CUR_LANGUAGE, } from "./mutations.type";

let state = {
    curLanguage: null,
  };
    
  const mutations = {
    [SET_CUR_LANGUAGE](state, lang) {
      state.curLanguage = lang;
    }
  }

  const getters = {
    curLanguage(state) {
        return state.curLanguage
    }
}


  export default {
    state,
    mutations,
    getters
};